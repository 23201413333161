import React, { useState } from "react"
import { Row, Col, Form } from "react-bootstrap"
import { TextField, TextArea, Button } from "../ui"
import { sendEmail } from "../../utils"
import "./ContactForm.scss"

function ContactForm() {
  const [name, setName] = useState("")
  const [mail, setMail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [messageSent, setMessageSent] = useState(false)
  const [messageError, setMessageError] = useState(null)

  const sendFormData = async e => {
    e.preventDefault()

    const fromEmail = mail
    const body = `
      <p>Name: ${name}</p>
      <p>Email: ${mail}</p>
      <p>Phone: ${phone}</p>
      <p>Message: ${message}</p>
    `

    try {
      await sendEmail({ body, fromEmail })
      setMessageSent(true)

      resetForm()
    } catch (error) {
      setMessageError(error)

      setTimeout(() => {
        setMessageError(null)
      }, 5000) // reset after 5 secs
    } finally {
    }
  }

  const resetForm = () => {
    setTimeout(() => {
      setName("")
      setMail("")
      setPhone("")
      setMessage("")
      setMessageSent(false)
      setMessageError(null)
    }, 5000) // reset after 5 secs
  }

  return (
    <div className="contactForm__container">
      <Form>
        <Row>
          <Col>
            <label>Your Name</label>
            <br />
            <TextField
              type="text"
              placeholder=""
              onChange={e => setName(e.target.value)}
              customClass="contactForm__textField"
              value={name}
            />
            <br />
            <label>Mail</label>
            <br />
            <TextField
              type="email"
              placeholder=""
              onChange={e => setMail(e.target.value)}
              customClass="contactForm__textField"
              value={mail}
            />
            <br />
            <label>Phone</label>
            <br />
            <TextField
              type="number"
              placeholder=""
              onChange={e => setPhone(e.target.value)}
              customClass="contactForm__textField"
              value={phone}
            />
          </Col>
          <Col>
            <label>Message</label>
            <br />
            <TextArea
              placeholder=""
              onChange={e => setMessage(e.target.value)}
              customClass="contactForm__textArea"
              value={message}
            />
          </Col>
        </Row>
        {messageSent && (
          <h5 className="contactForm__messageSent">
            Your has been message sent, we'll contact you shortly
          </h5>
        )}
        {messageError && (
          <h5 className="contactForm__messageError">Error: {messageError}</h5>
        )}
        <br />
        <Button
          title="Send"
          type="submit"
          disabled={!name || !mail || !phone || !message}
          customClass="contactForm__btn"
          onClick={sendFormData}
        />
        <br />
        <br />
      </Form>
    </div>
  )
}

export default ContactForm
