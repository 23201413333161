import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import SignUpToNewsLetter from "../Global/SignUpToNewsLetter"
import ContactForm from "./ContactForm"
import { Title } from "../ui"
import "./index.scss"
import Location from "../../assets/images/contact/location.svg"
import Mail from "../../assets/images/contact/mail.svg"

function Contact() {
  const emailAddress = process.env.GATSBY_EMAIL_ADDRESS

  return (
    <div className="contact__container">
      <Container>
        <Title header="Get in touch" color="BlackRussian" />
        <p>We would love to hear from you.</p>
        <Row>
          <div className="contact__address">
            <Col>
              <div className="contact__location">
                <img src={Location} alt="location" />
                <p>
                  9th Floor, NeXSky Building,
                  <br />
                  Ebѐne Cybercity 72201,
                  <br />
                  Republic of Mauritius
                </p>
              </div>
            </Col>
            <Col>
              <div className="contact__location">
                <img src={Mail} alt="mail" />
                <br />
                <a
                  href={`mailto:${emailAddress}`}
                  title={emailAddress}
                  className="footer__links"
                  target="_blank"
                  rel="noreferrer"
                >
                  {emailAddress}
                </a>
              </div>
            </Col>
          </div>
        </Row>
        <ContactForm />
      </Container>
      {/* <SignUpToNewsLetter /> */}
    </div>
  )
}

export default Contact
