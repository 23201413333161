/* eslint-disable */

export const sendEmail = ({ body, fromEmail }) => {
  /* SmtpJS.com - v3.0.0 */

  var Email = {
    send: function(a) {
      return new Promise(function(n, e) {
        ;(a.nocache = Math.floor(1e6 * Math.random() + 1)), (a.Action = "Send")
        var t = JSON.stringify(a)
        Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function(e) {
          n(e)
        })
      })
    },
    ajaxPost: function(e, n, t) {
      var a = Email.createCORSRequest("POST", e)
      a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"),
        (a.onload = function() {
          var e = a.responseText
          null != t && t(e)
        }),
        a.send(n)
    },
    ajax: function(e, n) {
      var t = Email.createCORSRequest("GET", e)
      ;(t.onload = function() {
        var e = t.responseText
        null != n && n(e)
      }),
        t.send()
    },
    createCORSRequest: function(e, n) {
      var t = new XMLHttpRequest()
      return (
        "withCredentials" in t
          ? t.open(e, n, !0)
          : "undefined" != typeof XDomainRequest
          ? (t = new XDomainRequest()).open(e, n)
          : (t = null),
        t
      )
    },
  }

  return new Promise((resolve, reject) => {
    Email.send({
      Host: process.env.GATSBY_MAILGUN_HOST,
      Username: process.env.GATSBY_MAILGUN_USERNAME,
      Password: process.env.GATSBY_MAILGUN_PASSWORD,
      Port: 2525,
      To: process.env.GATSBY_EMAIL_SEND_TO,
      From: 'Licensing@finclusiongroup.com',
      Subject: "This email is an enquiry from Fin wbsite",
      Body: body,
    }).then(function(message) {
      if (message === "OK") {
        resolve(message)
      } else {
        reject(message)
      }
    })
  })
}
